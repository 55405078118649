import {Controller} from '@hotwired/stimulus'
import $ from 'jquery'
import ConceptScripts from '../conceptScripts'


export default class extends Controller {
    static values = {selecteduser: String}

    connect() {
        if (this.selecteduserValue) {
            localStorage.setItem('selectedUser', this.selecteduserValue)
        } else {
            localStorage.setItem('selectedUser', '')
        }
        $(document).ready(function () {
            const conceptSlidesClass = new ConceptScripts()

            let queryParams = conceptSlidesClass.getQueryParams()
            let partnerContact = $('#partnerContact')
            // let content = ''
            let contentContact = ''

            let getVermittler = null

            if (queryParams.vermittler) {

                getVermittler = queryParams.vermittler
                localStorage.setItem('vermittler', queryParams?.vermittler)

            } else if (localStorage.getItem('vermittler')) {

                getVermittler = localStorage.getItem('vermittler')

                let url = new URL(window.location.href)
                let params = url.searchParams

                params.set('vermittler', getVermittler)
                window.history.replaceState({}, '', url)
            }


            if (getVermittler) {

                $.get('https://www.ideal-versicherung.de/api/v1/onlineservice/ul/config/' + getVermittler, function (response) {
                    // console.log(response)
                    let vermittler = response.vermittler.kontaktdaten
                    localStorage.setItem('vermittlerCSS', response.theme)
                    $('<style>')
                        .prop('type', 'text/css')
                        .html(`${response.theme}`)
                        .appendTo('head')
                    $('<style>')
                        .prop('type', 'text/css')
                        .html(`
                            .backgroundColorCustom {
                                background-color: var(--primary-main) !important;
                            }
                            .textColorCustom {
                                color: var(--primary-main) !important;
                            }
                        `)
                        .appendTo('head')

                    contentContact = '<div class="col-12 col-sm-6 d-flex flex-column gap-1 align-items-start">'
                    contentContact += '<div class="d-flex flex-column gap-1 align-items-center">'
                    contentContact += '<div class="w-13 h-13 rounded-circle shadow drop-shadow-xs" style="background: var(--image-profil) no-repeat;background-position: center center;background-size: contain;">' + '</div>'
                    contentContact += '<div class="d-flex flex-column gap-1 fs-8">'
                    contentContact += '<div class="fw-semibold">' + vermittler.Vorname + ' ' + vermittler.Name + '</div>'
                    contentContact += '</div>'
                    contentContact += '</div>'
                    contentContact += '</div>'

                    contentContact += '<div class="col-12 col-sm-6 d-flex flex-column gap-1 align-items-start">'
                    contentContact += '<div class="w-15 h-13" style="background: var(--image-logo) no-repeat;background-position: center center;background-size: contain;">' + '</div>'
                    contentContact += '<div class="d-flex flex-column gap-1 fs-8">'
                    contentContact += '<div class="fw-semibold">' + vermittler.Firma + '</div>'
                    contentContact += '<div >' + vermittler.Strasse + ' ' + vermittler.Hausnummer + '</div>'
                    contentContact += '<div>' + vermittler.PLZ + ' ' + vermittler.Ort + '</div>'
                    contentContact += '</div>'
                    contentContact += '</div>'

                    partnerContact.append(contentContact)

                }).fail(function (error) {
                    console.log('Error:', error)
                })
            }else{
                $('<style>')
                    .prop('type', 'text/css')
                    .html(`
                            .backgroundColorCustom {
                                background-color: #003273 !important;
                            }
                            .textColorCustom {
                                color: #003273 !important;
                            }
                        `)
                    .appendTo('head')
            }

            let getItemsFromSessionStore = JSON.parse(sessionStorage.getItem('slidesSession'))
            let slideList = []

            if (getItemsFromSessionStore && getItemsFromSessionStore.length) {
                slideList = getItemsFromSessionStore
                $.each(getItemsFromSessionStore, function (key, val) {
                    let checkbox = $('input[data-input-id="' + val + '"]')
                    checkbox.prop('checked', true)
                    if (checkbox.is(':checked')) {
                        checkbox.closest('.slide-input').addClass('backgroundColorCustom')
                        checkbox.closest('.slide-input').addClass('border-primary border-opacity-50')
                        checkbox.closest('.slide-input').find('.slide-title').removeClass('text-primary').addClass('text-white')
                        checkbox.closest('.slide-input').find('.slide-link').removeClass('d-none').addClass('d-block')
                        checkbox.closest('.slide-input').find('.check-img').removeClass('d-none').addClass('d-block')
                        checkbox.closest('.slide-input').find('.slide-link-holder').removeClass('d-block').addClass('d-none')
                    }
                })
            }
            $('input.slideHandler:checkbox').change(function () {
                let id = $(this).data('input-id')
                if (this.checked) {
                    slideList.push(id)
                    let checkbox = $('input[data-input-id="' + id + '"]')

                    checkbox.closest('.slide-input').addClass('backgroundColorCustom')
                    checkbox.closest('.slide-input').addClass('border-primary border-opacity-50')
                    checkbox.closest('.slide-input').find('.slide-title').removeClass('text-primary').addClass('text-white')
                    checkbox.closest('.slide-input').find('.slide-link').removeClass('d-none').addClass('d-block')
                    checkbox.closest('.slide-input').find('.check-img').removeClass('d-none').addClass('d-block')
                    checkbox.closest('.slide-input').find('.slide-link-holder').removeClass('d-block').addClass('d-none')

                } else {
                    if (slideList.length) {
                        slideList = slideList.filter(function (value) {
                            return value !== id
                        })
                    }
                    let checkbox = $('input[data-input-id="' + id + '"]')

                    checkbox.closest('.slide-input').removeClass('backgroundColorCustom')
                    checkbox.closest('.slide-input').removeClass('border-primary border-opacity-50')
                    checkbox.closest('.slide-input').find('.slide-title').addClass('text-primary').removeClass('text-white')
                    checkbox.closest('.slide-input').find('.slide-link').removeClass('d-block').addClass('d-none')
                    checkbox.closest('.slide-input').find('.check-img').removeClass('d-block').addClass('d-none')
                    checkbox.closest('.slide-input').find('.slide-link-holder').removeClass('d-none').addClass('d-block')

                }
                slideList = slideList.sort(function (a, b) {
                    return a - b
                })
                sessionStorage.setItem('slidesSession', JSON.stringify(slideList))
            })
        })
    }
}