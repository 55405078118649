import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
    // Define a static property named 'values' with an object as its value
    static values = {
        payload: String, editmode: Boolean, id: String,
    }
    static targets = ['btn']

    connect() {
        let payload = JSON.parse(this.payloadValue)  // Parse the 'payloadValue' and assign it to the variable 'payload'
        let editmode = JSON.parse(this.editmodeValue)    // Parse the 'editmodeValue' and assign it to the variable 'editmode'


        window.onload = async () => {
            const modalData = JSON.parse(payload.modalData).length > 0 ? JSON.parse(payload.modalData) : []
            const svgModalKey = payload.svgModalKey

            // Select the SVG element by its key and store it in this.overrides
            this.overrides = $('[data-name="' + svgModalKey + '"]')

            // Parse the text content of the SVG element to get the list of tours
            this.list = this.overrides.text() !== '' ? JSON.parse(this.overrides.text()) : []

            // Get the container element by its ID
            const container = document.getElementById('svgContainer')

            // Initialize the steps array with an intro step
            this.steps = [{
                title: payload.intro_title, content: payload.intro_text,
            }]

            // Select the text and SVG containers by their IDs
            this.textContainer = $('#textContainer')
            this.svgDiv = $('#svgContainer')

            // If editmode is false, parse the modal data and add steps to the list
            if (!editmode) {
                if (modalData.length > 0) {
                    modalData.map(items => {
                        // Add a new step for each item in the modal data
                        this.steps.push({
                            element: '#' + items.id, title: items.title, content: items.description, placement: 'top',
                        })

                        // Append a div to the SVG container for each tour
                        this.svgDiv.append('<div id="' + items.id + '" class="bg-primary-dark bg-opacity-25"></div>')

                        // Style the appended div with absolute positioning and dimensions
                        $('#' + items.id)
                            .css('top', items.top - 2.5 + 'px')
                            .css('left', items.left - 2 + 'px')
                            .css('position', 'absolute')
                            .css('width', items.width + 5 + 'px')
                            .css('height', items.height + 5 + 'px')
                            .css('border', '0.5px solid #003273')
                    })
                }
            } else {
                // If editmode is true, render the existing list of tours
                if (this.list.length > 0) {
                    renderTour(this.list, this.svgDiv, this.textContainer)
                    $('.modals').hide()
                }
            }
            if (editmode) {
                // Add a mouseup event listener to the container element
                container.addEventListener('mouseup', (event) => {

                    // Get the current selection from the user
                    const selection = window.getSelection()

                    // Extract the text of the selected range
                    const selectedText = selection.toString()

                    // Check if there is a valid selection and it contains text
                    if (selection && selection.rangeCount > 0 && selectedText.trim()) {
                        
                        // Get the current bounding rectangle of the selected range
                        const range = selection.getRangeAt(0)
                        const rect = range.getBoundingClientRect()

                        // Calculate the reference rectangle (container's bounding box)
                        const refRect = container.getBoundingClientRect()

                        // Determine the parent element ID of the common ancestor container
                        let parentID = range.commonAncestorContainer.parentElement.id

                        // Generate a unique target tour ID if a parent ID exists, otherwise use a random string
                        let targetTourId = null
                        if (parentID) {
                            targetTourId = parentID + '-' + Math.floor(Math.random() * 99999)
                        } else {
                            targetTourId = 'target-tour-' + Math.floor(Math.random() * 99999)
                        }

                        // Calculate the offsetX and offsetY positions for positioning the modal
                        const offsetX = rect.left - refRect.left
                        const offsetY = rect.top - refRect.top

                        // Create a button to open the tour modal
                        this.svgDiv.append('<button id="open_' + targetTourId + '" data-action="web-tour#open" class="bg-primary bg-opacity-10"></button>')

                        // Style the button with absolute positioning and dimensions
                        $('#open_' + targetTourId)
                            .css('top', offsetY - 1.5 + 'px')
                            .css('left', offsetX - 1.5 + 'px')
                            .css('position', 'absolute')
                            .css('width', rect.width + 4 + 'px')
                            .css('height', rect.height + 3 + 'px')
                            .css('border', '0.5px solid #003273')

                        // Append the modal content to the text container
                        this.textContainer.append(renderModal(targetTourId))

                        // Style the modal with absolute positioning and dimensions
                        $('#modal-' + targetTourId)
                            .css('top', offsetY + rect.height + 25 + 'px')

                        // Push an object containing tour details to the list array
                        this.list.push({
                            'id': targetTourId,
                            'top': offsetY,
                            'left': offsetX,
                            'width': rect.width,
                            'height': rect.height,
                            'title': '',
                            'description': '',
                        })

                        // Update the overrides with the current list of tours
                        this.overrides.text(JSON.stringify(this.list))

                        // Remove all selected ranges to prevent any further selection events affecting the tour modal
                        selection.removeAllRanges()
                    }
                })
            }

            try {
                // Loads a CSS file and waits for it to be loaded before proceeding.
                await loadCSS('/static/js/webtour/webtour.min.css')

                // Loads a JavaScript file and waits for it to be loaded before proceeding.
                await loadJS('/static/js/webtour/webtour.js')
            } catch (error) {
                // Logs any errors that occur during the loading process to the console.
                console.error(error)
            }

            this.wt = new WebTour()

            // Configure options for the web tour
            this.wt.options = {
                animate: true,               // Enable animation during transitions
                opacity: 0.5,                // Set the initial opacity of tooltips
                offset: 20,                 // Offset from the target element
                borderRadius: 3,              // Border radius for tooltips
                allowClose: false,            // Prevent closing the tour via a close button
                highlight: true,               // Enable highlighting of targets with a circle or rectangle
                highlightOffset: 5,             // Distance between the target and the highlight element
                keyboard: true,               // Allow navigation via keyboard arrows
                width: '500px',             // Set the width of tooltips
                zIndex: 10050,              // Set the z-index for tooltips
                removeArrow: false,            // Do not display an arrow on tooltips
            }

            this.wt.setSteps(this.steps)

            // Start the web tour if editmode is false
            if (!editmode) {
                await this.wt.start()
            }

            // Event listener for hash change events in the URL
            document.addEventListener('hashchange', () => {
                // Get the current hash from the window location
                const hash = window.location.hash

                // Split the hash to separate the section ID
                let sectionId = hash.split('#')

                // Use the section ID to scroll to the corresponding element smoothly
                document.getElementById(sectionId[1]).scrollIntoView({ behavior: 'smooth' })
            })
        }
    }

    /**
     * Handles opening a modal based on the event target.
     *
     * @param {Event} event - The click event that triggered this function.
     */
    open(event) {
        // Hide all modals
        $('.modals').hide()

        // Extract the modal ID from the event target
        const modalId = event.target.id.split('open_')[1]

        // Show the corresponding modal by its ID
        $('#modal-' + modalId).show()
    }

    /**
     * Handles closing a modal based on the event target.
     *
     * @param {Event} event - The click event that triggered this function.
     */
    close(event) {
        // Extract the modal ID from the event target
        const modalId = event.target.id.split('close_')[1]

        // Hide the corresponding modal by its ID
        $('#modal-' + modalId).hide()
    }

    /**
     * Updates the title of a list element based on the event target.
     *
     * @param {Event} event - The input event that triggered this function.
     */
    updatetitle(event) {
        // Extract the ID from the event target
        const id = event.target.id.split('overrideTitle_')[1]

        // Check if the list and its length are defined
        if (this.list !== undefined && this.list.length > 0) {
            // Find the element in the list by ID and update its title
            this.list.find(element => {
                if (element.id === id) {
                    element.title = event.target.value
                }
            })

            // Update the overrides text with the updated list
            this.overrides.text(JSON.stringify(this.list))
        }
    }


    /**
     * Updates the description for a specific tour item.
     *
     * @param {Event} event - The event object containing information about the input change.
     */
    updatedescription(event) {
        // Extract the ID of the target element by splitting the string 'overrideDescription_'
        const id = event.target.id.split('overrideDescription_')[1]

        // Check if the list is defined and not empty
        if (this.list !== undefined && this.list.length > 0) {
            // Find the tour item in the list that matches the provided ID
            this.list.find(element => {
                if (element.id === id) {
                    // Update the description of the matching item
                    element.description = event.target.value
                }
            })

            // Convert the updated list to a JSON string and update the overrides text field
            this.overrides.text(JSON.stringify(this.list))
        }
    }


    /**
     * Deletes a tour item from the list.
     *
     * @param {Event} event - The event object containing information about the delete action.
     */
    delete(event) {
        // Extract the ID of the target element by splitting the string 'delete_'
        const id = event.target.id.split('delete_')[1]

        // Log the ID for debugging purposes
        console.log(event.target.id)

        // Check if the list is defined and not empty
        if (this.list !== undefined && this.list.length > 0) {
            // Find the index of the tour item in the list that matches the provided ID
            let index = this.list.findIndex(element => element.id === id)

            // If the item is found, remove it from the list using splice
            if (index !== -1) {
                this.list.splice(index, 1)
            }

            // Convert the updated list to a JSON string and update the overrides text field
            this.overrides.text(JSON.stringify(this.list))

            // Remove the corresponding HTML elements with IDs 'open_' + id and 'modal-' + id
            $('#open_' + id).remove()
            $('#modal-' + id).remove()
        }
    }


    /**
     * Starts a tour if it's not already running.
     *
     * @param {Event} event - The event object containing information about the start action.
     */
    start(event) {
        // Check if the web tour is not currently running
        if (!this.wt.isRunning) {
            // Start the web tour by calling the start method of the wt instance
            this.wt.start()
        }
    }
}


function loadCSS(href) {
    // Create a new <link> element for the stylesheet
    const link = document.createElement('link')

    // Set the rel attribute to 'stylesheet' indicating it's a CSS file
    link.rel = 'stylesheet'

    // Set the href attribute to the URL of the CSS file to be loaded
    link.href = href

    // Define an event listener for when the CSS file is successfully loaded
    link.onload = () => {
        console.log(`CSS geladen: ${href}`) // Log a success message to the console
        resolve(`CSS geladen: ${href}`) // Resolve the promise with a success message
    }

    // Define an event listener for when the CSS file fails to load
    link.onerror = () => {
        console.error(`Fehler beim Laden der CSS-Datei: ${href}`) // Log an error message to the console
        reject(`Fehler beim Laden der CSS-Datei: ${href}`) // Reject the promise with an error message
    }

    // Append the <link> element to the head of the document
    document.head.appendChild(link)
}


function loadJS(src) {
    return new Promise((resolve, reject) => {
        // Create a new script element
        const script = document.createElement('script')

        // Set the source URL for the script
        script.src = src

        // Mark the script as asynchronous to improve performance
        script.async = true

        // Handle successful loading of the script
        script.onload = () => {
            // Resolve the promise with a success message indicating the script was loaded
            resolve(`JS geladen: ${src}`)
        }

        // Handle errors during the loading process
        script.onerror = () => {
            // Reject the promise with an error message indicating the load failure
            reject(`Fehler beim Laden der JS-Datei: ${src}`)
        }

        // Append the script element to the document's body
        document.body.appendChild(script)
    })
}

function renderTour(list, svgDiv, textContainer) {
    list.map(items => {
        svgDiv.append('<button id="open_' + items.id + '" data-action="web-tour#open" class="bg-primary bg-opacity-10"></button>')
        $('#open_' + items.id).css('top', items.top - 1.5 + 'px')
            .css('left', items.left - 1.5 + 'px')
            .css('position', 'absolute')
            .css('width', items.width + 4 + 'px')
            .css('height', items.height + 3 + 'px')
            .css('border', '0.5px solid #003273')
        textContainer.append('<div id="modal-' + items.id + '"\n' + '                     class="modals position-absolute start-50 translate-middle-x mw-lg-3xl w-100 min-vh-20 mx-auto position-relative bg-white shadow drop-shadow-sm px-4 py-5 "\n' + '                     style="z-index: 9999999;">\n' + '                    <div\n' + '                        class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end gap-2 pt-2 pe-2">\n' + '                        <button id="delete_' + items.id + '" data-action="web-tour#delete" data-web-tour-id-value="' + items.id + '" class="cursor-pointer fs-9 fw-medium border" style="border-color: rgba(139,0,0,0.5);background-color: rgba(139,0,0,0.1)">Löschen</button>\n' + '                        <button id="close_' + items.id + '" data-action="web-tour#close" data-web-tour-id-value="' + items.id + '" class="cursor-pointer fs-9 fw-medium bg-primary text-white border border-primary">Schließen</button>\n' + '                    </div>\n' + '                    <div class="w-100 gap-2 d-flex flex-row justify-content-start">\n' + '                        <label class="w-10 text-primary" for="overrideTitle-' + items.id + '">Titel:</label>\n' + '                        <input data-action="input->web-tour#updatetitle" id="overrideTitle_' + items.id + '" type="text" name="override-title-' + items.id + '" value="' + items.title + '">\n' + '                    </div>\n' + '                    <div class="w-100 d-flex flex-column justify-content-start">\n' + '                        <label class="w-100 text-primary" for="overrideDescription-' + items.id + '">Beschreibung:</label>\n' + '                       <textarea data-action="input->web-tour#updatedescription" name="override-description-' + items.id + '" class="p-3" id="overrideDescription_' + items.id + '" cols="30" rows="10">' + items.description + '</textarea>\n' + '                    </div>\n' + '                </div>')
        $('#modal-' + items.id).css('top', items.top + items.height + 65 + 'px')
    })
}

function renderModal(id) {
    return '<div style="z-index: 999999;" id="modal-' + id + '"\n' + '                     class="modals position-absolute start-50 translate-middle-x mw-lg-3xl w-100 min-vh-20 mx-auto position-relative bg-white shadow drop-shadow-sm px-4 py-5">\n' + '                    <div\n' + '                        class="position-absolute top-0 end-0 d-flex align-items-center justify-content-end gap-2 pt-2 pe-2">\n' + '                        <button id="delete_' + id + '" data-action="web-tour#delete" data-web-tour-id-value="' + id + '" class="cursor-pointer fs-9 fw-medium border" style="border-color: rgba(139,0,0,0.5) !important; background-color: rgba(139,0,0,0.1)">Löschen</button>\n' + '                        <button id="close_' + id + '" data-action="web-tour#close" data-web-tour-id-value="' + id + '" class="cursor-pointer fs-9 fw-medium bg-primary text-white border border-primary">Schließen</button>\n' + '                    </div>\n' + '                    <div class="w-100 gap-2 d-flex flex-row justify-content-start">\n' + '                        <label class="w-10 text-primary" for="overrideTitle-' + id + '">Titel:</label>\n' + '                        <input data-action="input->web-tour#updatetitle" id="overrideTitle_' + id + '" type="text" name="override-title-' + id + '">\n' + '                    </div>\n' + '                    <div class="w-100 d-flex flex-column justify-content-start">\n' + '                        <label class="w-100 text-primary" for="overrideDescription-' + id + '">Beschreibung:</label>\n' + '                        <textarea data-action="input->web-tour#updatedescription" name="override-description-' + id + '" class="p-3" id="overrideDescription_' + id + '" cols="30" rows="10"></textarea>\n' + '                    </div>\n' + '                </div>'
}

