import {Controller} from '@hotwired/stimulus'
import $ from 'jquery'

const currLoc = window.location.origin
export default class extends Controller {
    static values = {slides: String}
    static targets = ["modal", "content"];

    connect() {
        let slides_arr = JSON.parse(this.slidesValue)
        this.subpages = []
        let app = $('#app')

        requestHandler(slides_arr)

        $(document).ready(function () {
            let queryParams = getQueryParams()
            let getVermittler = null
            if (queryParams.vermittler) {
                getVermittler = queryParams.vermittler
                localStorage.setItem('vermittler', queryParams?.vermittler)
            } else if (localStorage.getItem('vermittler')) {
                getVermittler = localStorage.getItem('vermittler')
                let url = new URL(window.location.href)
                let params = url.searchParams
                params.set('vermittler', getVermittler)
                window.history.replaceState({}, '', url)
            }
            const vermittlerCss = localStorage.getItem('vermittlerCSS')
            if (vermittlerCss) {
                $('<style>')
                    .prop('type', 'text/css')
                    .html(`${vermittlerCss}`)
                    .appendTo('head')
                $('<style>')
                    .prop('type', 'text/css')
                    .html(`
                            .backgroundColorCustom {
                                background-color: var(--primary-main) !important;
                            }
                            .textColorCustom {
                                color: var(--primary-main) !important;
                            }
                        `).appendTo('head')
            } else {
                if (getVermittler) {
                    $.get('https://www.ideal-versicherung.de/api/v1/onlineservice/ul/config/' + getVermittler, function (response) {
                        localStorage.setItem('vermittlerCSS', response.theme)
                        $('<style>')
                            .prop('type', 'text/css')
                            .html(`${response.theme}`)
                            .appendTo('head')
                        $('<style>')
                            .prop('type', 'text/css')
                            .html(`
                            .backgroundColorCustom {
                                background-color: var(--primary-main) !important;
                            }
                            .textColorCustom {
                                color: var(--primary-main) !important;
                            }
                        `).appendTo('head')
                    }).fail(function (error) {
                        console.log('Error:', error)
                    })
                }else{
                    $('<style>')
                        .prop('type', 'text/css')
                        .html(`
                            .backgroundColorCustom {
                                background-color: #003273 !important;
                            }
                            .textColorCustom {
                                color: #003273 !important;
                            }
                        `)
                        .appendTo('head')
                }
            }

            let getSelectedUser = localStorage.getItem('selectedUser')
            if (getSelectedUser) {
                $('<style>')
                    .prop('type', 'text/css')
                    .html(`
                            @font-face {
                                font-family: 'BaloiseRegularText';
                                src: url('https://www.ideal-versicherung.de/static/cdn/fonts/extern/BaloiseCreate/BaloiseCreateText/WOFF2/BaloiseCreateText-Regular.woff2') format('woff2');
                                font-display: swap;
                            }
                    
                            @font-face {
                                font-family: 'BaloiseRegularHeadline';
                                src: url('https://www.ideal-versicherung.de/static/cdn/fonts/extern/BaloiseCreate/BaloiseCreateHeadline/WOFF2/BaloiseCreateHeadline-Regular.woff2') format('woff2');
                                font-display: swap;
                            }
                    
                            body {
                                font-family: 'BaloiseRegularText', sans-serif !important;
                            }
                    
                            .baloise-headline {
                                font-family: 'BaloiseRegularHeadline', sans-serif !important;
                            }
                        `).appendTo('head')
            }else{
                $('<style>')
                    .prop('type', 'text/css')
                    .html(`
                            .baloise-headline {
                                
                            }
                        `).appendTo('head')
            }

            $('#app .carousel-item:first-child').addClass('active')
            $('#slideButtonContainer').show().removeClass('d-none').addClass('d-flex')

            if ($('.active').index() === 0) {
                $('#btnPrevious').addClass('d-none').removeClass('d-block')
                $('#BactToStart').addClass('d-none').removeClass('d-block')
            } else {
                $('#btnPrevious').addClass('d-block').removeClass('d-none')
            }

            let child = slides_arr.length - 1
            if ($('.active').index() === child) {
                $('#btnNext').addClass('d-none')
            }

            let slide = slides_arr[$('.active').index()]
            checkIconLinks(slide, '#SlideLinks')

            $('#BactToStart').on('click', function () {
                $('#btnStart').show()
                $('#app .carousel-item').removeClass('active')
                $('#app .carousel-item:first-child').addClass('active')

                if ($('.active').index() === 0) {
                    $('#BactToStart').addClass('d-none').removeClass('d-block')
                    $('#btnNext').addClass('d-block').removeClass('d-none')
                    $('#btnPrevious').addClass('d-none').removeClass('d-block')
                }
                $('#SlideLinks .icons-links').remove()
                let slide = slides_arr[$('.active').index()]
                checkIconLinks(slide, '#SlideLinks')
            })

            $('#btnNext').click(function () {
                if ($('.active').index() === 0) {
                    $('#BactToStart').addClass('d-block').removeClass('d-none')
                }

                if ($('.active').index() + 1 === 1) {
                    $('#btnPrevious').addClass('d-none')
                } else {
                    $('#btnPrevious').removeClass('d-none').addClass('d-block')
                }
                let child = slides_arr.length - 1
                if ($('.active').index() + 1 === child) {
                    $('#btnNext').addClass('d-none')
                } else {
                    $('#btnNext').removeClass('d-none').addClass('d-block')
                }

                let slide = slides_arr[$('.active').index() + 1]
                checkIconLinks(slide, '#SlideLinks')
            })

            $('#btnPrevious').click(function () {
                if ($('.active').index() - 1 === 0) {
                    $('#BactToStart').addClass('d-block').removeClass('d-none')
                }

                if ($('.active').index() - 1 === 1) {
                    $('#btnPrevious').addClass('d-none').removeClass('d-block')
                } else {
                    $('#btnPrevious').removeClass('d-none').addClass('d-block')
                }

                let child = slides_arr.length - 1

                if ($('.active').index() - 1 === child) {
                    $('#btnNext').addClass('d-none')
                } else {
                    $('#btnNext').removeClass('d-none').addClass('d-block')
                }

                let slide = slides_arr[$('.active').index() - 1]
                checkIconLinks(slide, '#SlideLinks')
            })
        })

        function requestHandler(slideList) {
            $('.slides').remove() // remove all slides from template
            $('.slide-btn').remove() // remove all indicators from template

            if (slideList.length) {
                $.each(slideList, function (key, val) {
                    let d_flex = ''
                    let text_pos = ''
                    if (val.content_position === 'left' || val.content_position === '') {
                        d_flex = ''
                        text_pos = 'align-items-start'
                    } else if (val.content_position === 'right') {
                        d_flex = 'flex-wrap flex-row-reverse'
                        text_pos = 'align-items-end'
                    }
                    app.append(loadSlideContent(key, val, d_flex, text_pos))
                })
            }
        }
    }

    loadCardSubpages(event) {
        console.log(event.target.dataset.cardObject)
        $('#innerModal .carousel-item').remove()
        this.subpages = JSON.parse(event.target.dataset.fullphotoObject)
        this.modalTarget.classList.add("d-flex");


        $.each(this.subpages, function (key, val) {
            console.log(JSON.parse(val.slides)[0])
            let d_flex = ''
            let text_pos = ''
            if (JSON.parse(val.slides)[0].content_position === 'left' || JSON.parse(val.slides)[0].content_position === '') {
                d_flex = ''
                text_pos = 'align-items-start'
            } else if (JSON.parse(val.slides)[0].content_position === 'right') {
                d_flex = 'flex-wrap flex-row-reverse'
                text_pos = 'align-items-end'
            }
            let sli = loadSlideContent(key, JSON.parse(val.slides)[0], d_flex, text_pos)
            $('#innerModal').append(sli)
            $('#innerModal .carousel-item:first-child').addClass('active')
        })

        $('#slideButtonContainerModal').show().removeClass('d-none').addClass('d-flex')
        if ($('#innerModal .carousel-item.active').index() === 0) {
            $('#btnPreviousModal').addClass('d-none').removeClass('d-block')
            $('#BactToStartModal').addClass('d-none').removeClass('d-block')
        } else {
            $('#btnPreviousModal').addClass('d-block').removeClass('d-none')
        }
        console.log(this.subpages.length > 1)
        if (this.subpages.length > 1) {
            $('#btnNextModal').addClass('d-block').removeClass('d-none')
        }

        let child = this.subpages.length - 1
        if ($('#innerModal .carousel-item.active').index() === child) {
            $('#btnNextModal').addClass('d-none').removeClass('d-block')
        }
        let slide = this.subpages[$('#innerModal .carousel-item.active').index()]
        if (JSON.parse(slide.slides).length) {
            slide = JSON.parse(slide.slides)

            if (Object.hasOwn(slide[0], 'icons_items') && slide[0].icons_items !== 'undefined' && slide[0].icons_items.length) {
                checkIconLinks(slide[0], '#SlideLinksModal')
            } else {
                $('#SlideLinksModal .icons-links').remove()
            }
        }


    }

    nextslide() {
        if ($('#innerModal .carousel-item.active').index() === 0) {
            $('#BactToStartModal').addClass('d-block').removeClass('d-none')
        }

        if ($('#innerModal .carousel-item.active').index() === -1) {
            $('#btnPreviousModal').addClass('d-none')
        } else {
            $('#btnPreviousModal').removeClass('d-none').addClass('d-block')
        }
        let child = this.subpages.length - 1
        if ($('#innerModal .carousel-item.active').index() + 1 === child) {
            $('#btnNextModal').addClass('d-none')
        } else {
            $('#btnNextModal').removeClass('d-none').addClass('d-block')
        }

        let slide = this.subpages[$('#innerModal .carousel-item.active').index() + 1]
        if (JSON.parse(slide.slides).length) {
            slide = JSON.parse(slide.slides)

            if (Object.hasOwn(slide[0], 'icons_items') && slide[0].icons_items !== 'undefined' && slide[0].icons_items.length) {
                checkIconLinks(slide[0], '#SlideLinksModal')
            } else {
                $('#SlideLinksModal .icons-links').remove()
            }
        }
    }

    previousslide() {
        if ($('#innerModal .carousel-item.active').index() - 1 === 0) {
            $('#BactToStartModal').addClass('d-block').removeClass('d-none')
        }

        if ($('#innerModal .carousel-item.active').index() - 1 === 0) {
            $('#btnPreviousModal').addClass('d-none').removeClass('d-block')
        } else {
            $('#btnPreviousModal').removeClass('d-none').addClass('d-block')
        }

        let child = this.subpages.length - 1

        if ($('#innerModal .carousel-item.active').index() - 1 === child) {
            $('#btnNextModal').addClass('d-none')
        } else {
            $('#btnNextModal').removeClass('d-none').addClass('d-block')
        }

        let slide = this.subpages[$('#innerModal .carousel-item.active').index() - 1]
        if (JSON.parse(slide.slides).length) {
            slide = JSON.parse(slide.slides)

            if (Object.hasOwn(slide[0], 'icons_items') && slide[0].icons_items !== 'undefined' && slide[0].icons_items.length) {
                checkIconLinks(slide[0], '#SlideLinksModal')
            } else {
                $('#SlideLinksModal .icons-links').remove()
            }
        }
    }

    close(event) {
        event.preventDefault();
        this.modalTarget.classList.remove("d-flex");
    }
}

function loadSlideContent(key, value, contentPos, text_pos) {
    let content = ''
    let description_width = ''
    let title_width = ''
    if (value.description_width) {
        description_width = value.description_width
    } else {
        description_width = 'col-12 col-md-8 col-lg-4'
    }
    if (value.title_width) {
        title_width = value.title_width
    } else {
        title_width = 'col-12 col-md-10 col-lg-7'
    }

    if (value.template === 'slides\\index.html.twig') {
        content += '<div class="carousel-item slides w-100" style="position: unset !important;">'
        content += '<div class="min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100 row position-relative ' + contentPos + '">'
        content += '<div class="bg-white rounded col-12 col-lg-5 p-4 p-lg-5 h-auto d-flex flex-column justify-content-start shadow drop-shadow position-relative" style="gap: 20px; z-index: 2;">'
        if (value.title) {
            content += '<h1 class="fs-3 fs-lg-2 fw-bold textColorCustom baloise-headline animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
        }
        if (value.description) {
            content += '<div class="mt-5 fs-5 textColorCustom animate__animated animate__fadeInUp animate__delay-2s">' + value.description + '</div>'
        }
        if (value.side_photo) {
            content += '<div class="position-relative mt-10" style="z-index: 0">'
            content += '<img src="' + currLoc + value.side_photo + '" class=" w-100 h-100 animate__animated animate__fadeInUp animate__delay-2s" style="z-index: 0;" alt="">'
            content += '</div>'
        }
        content += '</div>'
        content += '<div class="col-12 col-lg-7 h-auto py-3 ps-4 pe-3 position-relative d-flex align-items-center justify-content-center" style="z-index: 0;">'
        if (value.slide_img) {
            content += '<img src="' + currLoc + value.slide_img + '" class="w-100 position-relative rounded shadow-sm drop-shadow-sm animate__animated animate__fadeIn" alt="">'
            //  content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; background-size: cover; object-position: center" alt="">'
        }
        content += '</div>'
        content += '</div>'
        content += '</div>'
    }

    if (value.template === 'slides\\fullPhoto.html.twig') {

        let text_position = ''
        if (text_pos === 'align-items-end') {
            text_position = 'text-end'
        }

        content += '<div class="carousel-item slides w-100 min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100">'
        content += '<div class="row ' + contentPos + '">'

        content += '<div class="w-100 p-4 p-lg-5 min-vh-lg-75 d-flex flex-column justify-content-start ' + text_pos + '" style="gap: 20px; z-index: 2;">'
        if (value.title) {
            content += '<h1 class="' + title_width + ' ' + text_position + ' fs-3 fs-lg-2 px-3 px-lg-5 py-2 textColorCustom baloise-headline rounded-1 bg-light bg-opacity-75 display-5 fw-bold animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
        }

        if (value.description) {
            content += '<div class="' + description_width + ' ' + text_position + ' mt-5 p-3 p-lg-5 fs-5 textColorCustom rounded-1 bg-light bg-opacity-75 animate__animated animate__fadeInUp animate__delay-2s" style="z-index: 3">' + value.description + '</div>'
        }
        if (value.full_photo) {
            content += '<div class="w-80 mx-auto position-absolute bottom-0 start-50 translate-middle-x" style="z-index: 0">'

            content += '<img src="' + currLoc + value.full_photo + '" class=" w-100 h-100 animate__animated animate__fadeIn" style="z-index: 0;" alt="">'

            content += '</div>'
        }

        content += '</div>'


        content += '<div class="w-100 h-100 position-absolute top-0 start-0 bg-white" style="z-index: 0;">'
        if (value.slide_img) {
            content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; object-position: center" alt="">'
        }
        content += '</div>'


        content += '</div>'
        content += '</div>'
    }

    if (value && value.template === 'slides\\cards.html.twig') {

        content += '<div class="carousel-item slides w-100 " style="position: unset !important;">'
        content += '<div class="min-vh-65 min-vh-lg-70 min-vh-xl-75 h-100 row ' + contentPos + '">'
        content += '<div class="w-100 h-100 position-absolute top-0 start-0 bg-white" style="z-index: 0;">'
        if (value.slide_img) {
            content += '<img src="' + currLoc + value.slide_img + '" class=" w-100 h-100 position-absolute top-0 end-0 animate__animated animate__fadeIn" style="z-index: 0; object-fit: cover; object-position: center" alt="">'
        }
        content += '</div>'
        content += '<div class="w-100 p-4 p-lg-5 position-relative d-flex flex-column justify-content-start" style="gap: 20px; z-index: 2;">'

        if (value.title) {
            content += '<h1 class="w-100 fs-3 fs-lg-2 px-3 bg-white bg-opacity-75 fw-bold text-center textColorCustom baloise-headline animate__animated animate__fadeInUp animate__delay-1s">' + value.title + '</h1>'
        }
        if (Object.hasOwn(value, 'card_items') && value.card_items !== 'undefined' && value.card_items.length) {
            if (value.card_version === 'card-6') {
                content += '<div class="w-100 px-3 row row-cols-3 justify-content-center">'
                value.card_items.map(element => {

                    if (element) {

                        content += '<div class="mw-sm mb-2 animate__animated animate__fadeInUp animate__delay-2s">'
                        content += '<div class="mw-xs mx-auto position-relative text-center">'
                        content += '<div class="w-100 bg-light position-relative d-flex overflow-hidden m-0 p-0 rounded-2 shadow drop-shadow-sm" style="max-height: 20vh;">'
                        if (element.card_img) {
                            content += '<img src="' + currLoc + element.card_img + '" alt="" title="" class="w-100 h-100 position-relative top-0 end-0 " style="z-index: 0; object-fit: cover; object-position: center">'
                        }
                        if (element.card_subpages && element.card_subpages.length > 0) {
                            let getHref = checkDocumentPage(element.card_subpages)
                            console.log(getHref)
                            if (getHref) {
                                content += '<a href="' + getHref.href + '" target="_blank" class="position-absolute top-0 end-0 w-10 h-10 border-0 bg-nav-link-color bg-opacity-75 d-flex place-items-center justify-content-center" style="border-bottom-left-radius: 6px;"> ' +
                                    '<svg style="z-index: 0;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                                    '                     width="18" height="20" viewBox="0 0 18 20">\n' +
                                    '                    <path \n' +
                                    '                        stroke="#F59B69"\n' +
                                    '                        stroke-width="1"\n' +
                                    '                        d="M5 20c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l8.646-8.646-8.646-8.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l9 9c0.195 0.195 0.195 0.512 0 0.707l-9 9c-0.098 0.098-0.226 0.146-0.354 0.146z"\n' +
                                    '                        fill="#F59B69"></path>\n' +
                                    '                </svg>' +
                                    '</a>'
                            } else {
                                content += '<button class="position-absolute top-0 end-0 w-10 h-10 border-0 bg-nav-link-color bg-opacity-75 d-flex place-items-center justify-content-center" style="border-bottom-left-radius: 6px;" data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\'> ' +
                                    '<svg data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\' style="z-index: 0;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                                    '                     width="18" height="20" viewBox="0 0 18 20">\n' +
                                    '                    <path data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\' \n' +
                                    '                        stroke="#F59B69"\n' +
                                    '                        stroke-width="1"\n' +
                                    '                        d="M5 20c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l8.646-8.646-8.646-8.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l9 9c0.195 0.195 0.195 0.512 0 0.707l-9 9c-0.098 0.098-0.226 0.146-0.354 0.146z"\n' +
                                    '                        fill="#F59B69"></path>\n' +
                                    '                </svg>' +
                                    '</button>'
                            }
                        }
                        content += '</div>'
                        content += '<div class="px-0 py-2">'
                        if (element.card_title) {
                            content += '<div class="mt-1 mb-2">'
                            content += '<h3 class="textColorCustom bg-white bg-opacity-75">' + element.card_title + '</h3>'
                            content += '</div>'
                        }

                        content += '</div>'
                        content += '</div>'
                        content += '</div>'
                    }
                })
                content += '</div>'
            }
            if (value.card_version === 'card-4') {
                content += '<div class="w-100 px-3 row row-cols-2 justify-content-center">'
                value.card_items.map((items, i) => {
                    content += '<div class="col-6 row flex-column align-items-center position-relative">'
                    if (items.card_uberschrift) {
                        content += '<div class="verticaltext_content text-cta-primary">' + items.card_uberschrift + '</div>'
                    }
                    if (items.cards.length) {
                        items.cards.map((element, n) => {
                            content += '<div class="mw-sm mb-2 animate__animated animate__fadeInUp animate__delay-2s">'
                            content += '<div class="mw-xs mx-auto position-relative text-center">'
                            content += '<div class="w-100 bg-light position-relative d-flex overflow-hidden m-0 p-0 rounded-2 shadow drop-shadow-sm" style="max-height: 20vh;">'
                            if (element.card_img) {
                                content += '<img src="' + currLoc + element.card_img + '" alt="" title="" class="w-100 h-100 position-relative top-0 end-0 " style="z-index: 0; object-fit: cover; object-position: center">'
                            }
                            if (element.card_subpages && element.card_subpages.length > 0) {
                                let getHref = checkDocumentPage(element.card_subpages)
                                if (getHref) {
                                    content += '<a href="' + getHref.href + '" target="_blank" class="position-absolute top-0 end-0 w-10 h-10 border-0 bg-nav-link-color bg-opacity-75 d-flex place-items-center justify-content-center" style="border-bottom-left-radius: 6px;"> ' +
                                        '<svg style="z-index: 0;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                                        '                     width="18" height="20" viewBox="0 0 18 20">\n' +
                                        '                    <path \n' +
                                        '                        stroke="#F59B69"\n' +
                                        '                        stroke-width="1"\n' +
                                        '                        d="M5 20c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l8.646-8.646-8.646-8.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l9 9c0.195 0.195 0.195 0.512 0 0.707l-9 9c-0.098 0.098-0.226 0.146-0.354 0.146z"\n' +
                                        '                        fill="#F59B69"></path>\n' +
                                        '                </svg>' +
                                        '</a>'
                                } else {
                                    content += '<button class="position-absolute top-0 end-0 w-10 h-10 border-0 bg-nav-link-color bg-opacity-75 d-flex place-items-center justify-content-center" style="border-bottom-left-radius: 6px;" data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\'> ' +
                                        '<svg data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\' style="z-index: 0;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                                        '                     width="18" height="20" viewBox="0 0 18 20">\n' +
                                        '                    <path data-action="click->fullphoto#loadCardSubpages" data-fullphoto-object=\'' + JSON.stringify(element.card_subpages) + '\' \n' +
                                        '                        stroke="#F59B69"\n' +
                                        '                        stroke-width="1"\n' +
                                        '                        d="M5 20c-0.128 0-0.256-0.049-0.354-0.146-0.195-0.195-0.195-0.512 0-0.707l8.646-8.646-8.646-8.646c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l9 9c0.195 0.195 0.195 0.512 0 0.707l-9 9c-0.098 0.098-0.226 0.146-0.354 0.146z"\n' +
                                        '                        fill="#F59B69"></path>\n' +
                                        '                </svg>' +
                                        '</button>'
                                }
                            }
                            content += '</div>'
                            content += '<div class="px-0 py-2">'
                            if (element.card_title) {
                                content += '<div class="mt-1 mb-2">'
                                content += '<h3 class="textColorCustom">' + element.card_title + '</h3>'
                                content += '</div>'
                            }
                            content += '</div>'
                            content += '</div>'
                            content += '</div>'
                        })
                    }
                    content += '</div>'

                })
                content += '</div>'
            }
        }
        if (value.description) {
            content += '<div class="w-100 text-center textColorCustom mt-2 px-3 animate__animated animate__fadeInUp animate__delay-3s">' + value.description + '</div>'
        }

        content += '</div>'
        content += '</div>'
        content += '</div>'
    }

    return content
}

function checkIconLinks(slides, id = "#SlideLinks") {
    let div = ''
    $(id + ' .icons-links').remove()
    if (slides && Object.hasOwn(slides, 'icons_items') && slides.icons_items !== 'undefined' && slides.icons_items.length) {
        div = ''
        if (slides.icons_items.length) {
            div += '<div class="icons-links w-auto py-2 px-4 ms-10 d-flex justify-content-end align-items-cente position-absolute top-0 end-0 rounded-bottom-1" style="gap: 16px; background-color: #434c5b; z-index: 10">'
            slides.icons_items.map(item => {
                div += '<div class="d-flex flex-column align-items-center gap-2">'
                div += '<a href="' + item.link.href + '" target="_blank" class="w-9 h-9 border-0 d-flex bg-light bg-opacity-10 rounded-2 align-items-center justify-content-center">'
                div += '<i class="bi ' + item.icon + ' fs-5 text-cta-primary" style="line-height: 0;"></i>'
                div += '</a>'
                div += '<div class="fs-7 text-cta-primary mt-1 pb-2" style="line-height: 0;">' + item.link.title + '</div>'
                div += '</div>'
            })
            div += '</div>'
            $(id).append(div)
        }
    } else {
        div = ''
        $(id + ' .icons-links').remove()
    }
}
function checkDocumentPage(slides) {
    let slide = slides.filter(items => {
        if (Object.hasOwn(items, 'href')) {
            if (items.href !== '') {
                return items
            }
        }
    })
    return slide[0]
}
function getQueryParams() {
    let params = {}
    let queryString = window.location.search.substring(1)
    let regex = /([^&=]+)=([^&]*)/g
    let match

    while (match = regex.exec(queryString)) {
        params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
    }

    return params
}