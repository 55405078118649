export default class ConceptScripts {
    getQueryParams = () => {
        let params = {}
        let queryString = window.location.search.substring(1)
        let regex = /([^&=]+)=([^&]*)/g
        let match

        while (match = regex.exec(queryString)) {
            params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
        }

        return params
    }
}
